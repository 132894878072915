import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TourMatMenuModule } from '../tour-md-menu/md-menu.module';
import { UtilsModule } from '../utils/utils.module';
import { BewatecDialogConfig } from './bewatec-dialog.config';
import { BewatecDialogComponent } from './components/bewatec-dialog/bewatec-dialog.component';
import { SpinnerButtonDirective } from './directives/spinner-button.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export function createTranslateLoader(
  http: HttpClient,
  bewatecDialogConfig: BewatecDialogConfig,
) {
  return new TranslateHttpLoader(
    http,
    bewatecDialogConfig.langEndpoint,
    '.json',
  );
}

@NgModule({
  declarations: [BewatecDialogComponent, SpinnerButtonDirective],
  imports: [
    MatProgressSpinnerModule,
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, BewatecDialogConfig],
      },
    }),
    UtilsModule,
    TourMatMenuModule,
  ],
  exports: [BewatecDialogComponent, SpinnerButtonDirective],
})
export class BewatecDialogModule {
  public static forRoot(
    config: BewatecDialogConfig,
  ): ModuleWithProviders<BewatecDialogModule> {
    return {
      ngModule: BewatecDialogModule,
      providers: [
        {
          provide: BewatecDialogConfig,
          useValue: config,
        },
      ],
    };
  }
}
