import { TranslateLoadingBaseComponent } from '@/app/components/translate-loading-base/translate-loading-base.component';
import { UpdateSelectedCustomer } from '@/app/external-modules/header/actions/select';
import {
  NotificationAction,
  NotificationType,
} from '@/app/external-modules/notifications/actions/notification.action';
import { Utils } from '@/app/utils';
import { CustomerFullDto, OpenAPIDefinitionService } from '@/openapi';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, inject, Injector, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'bewa-choose-customer-dialog',
  templateUrl: './choose-customer-dialog.component.html',
  styleUrls: ['./choose-customer-dialog.component.scss'],
})
export class ChooseCustomerDialogComponent extends TranslateLoadingBaseComponent {
  private readonly translate = inject(TranslateService);
  private readonly store = inject(Store);
  private dialogRef = inject(MatDialogRef<ChooseCustomerDialogComponent>);
  private _apiService = inject(OpenAPIDefinitionService);
  private utils = inject(Utils);

  availableCustomers: CustomerFullDto[] = [];
  selectedCustomer: CustomerFullDto;
  customerSelected = false;
  showNullCustomer = false;

  @ViewChild('autosize', { static: true }) autosize: CdkTextareaAutosize;
  filteredCustomers: Observable<CustomerFullDto[]>;
  customerControl = new FormControl('');

  nullCustomer: CustomerFullDto = {
    displayName: 'NOT_ASSIGNED',
    licenseModelId: 0,
    id: null!,
    name: 'NOT_ASSIGNED',
    testCustomer: true,
    enabled: false,
    deleted: false,
    distributorId: null!,
  };

  constructor() {
    super(inject(Injector));

    if (this.utils.isBewatecAdmin()) {
      this.prepareNullCustomer();
    }

    this._apiService.getCustomersFull().subscribe({
      next: (result) => {
        this.availableCustomers = result.content || [];
        this.filteredCustomers = this.customerControl.valueChanges.pipe(
          startWith(''),
          map((val) => {
            this.showNullCustomer = val?.length === 0;
            return this.filterCustomers(val);
          }),
        );
      },
      error: () => {
        this.store.dispatch(
          new NotificationAction(
            'NOTIFICATIONS.LOADING_CUSTOMERS_ERROR',
            NotificationType.SnackbarError,
          ),
        );
      },
    });
  }

  filterCustomers(name: string | null): CustomerFullDto[] {
    if (name && this.availableCustomers) {
      const filteredCustomers = this.availableCustomers.filter((c) =>
        c.displayName?.toLowerCase().includes(name.toLowerCase()),
      );
      if (this.utils.isBewatecAdmin() && this.showNullCustomer) {
        filteredCustomers.unshift(this.nullCustomer);
      }
      return filteredCustomers;
    } else {
      return this.availableCustomers;
    }
  }

  onSelectionChange($event: MatOptionSelectionChange, value: CustomerFullDto) {
    if ($event.isUserInput) {
      this.selectedCustomer = value;
      this.customerSelected = true;
      this.autosize.resizeToFitContent(true);
    }
  }

  onClickCancel() {
    this.dialogRef.close(false);
  }

  onClickOk() {
    if (this.customerSelected) {
      this.store.dispatch(new UpdateSelectedCustomer(this.selectedCustomer));
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }

  onRemoveCustomerSelection() {
    this.selectedCustomer = null!;
    this.customerSelected = false;
    this.customerControl.setValue(null);
    this.autosize.resizeToFitContent();
  }

  prepareNullCustomer() {
    this.nullCustomer.name = this.translate.instant(
      'CHOOSE_CUSTOMER_DIALOG.NOT_ASSIGNED_CUSTOMER',
    );
    this.nullCustomer.displayName = this.translate.instant(
      'CHOOSE_CUSTOMER_DIALOG.NOT_ASSIGNED_CUSTOMER',
    );
  }
}
