import { environment } from '@/environments/environment';
import { computed, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';
import { interval, Observable, Subscriber } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { Logger } from '../../external-modules/utils/logger/logger.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class WebsocketService implements OnDestroy {
  private readonly _oauthService = inject(OAuthService);
  private readonly _logger = inject(Logger);
  private readonly token = signal<string | undefined>(undefined);
  private readonly stompClient = signal<Stomp.Client | undefined>(undefined);
  private readonly isConnected = computed(
    () => this.stompClient()?.connected ?? false,
  );
  private readonly activeSubscriptions = new Map<
    string,
    Set<Subscriber<unknown>>
  >();
  private readonly pendingMessages = new Map<string, object>();

  constructor() {
    this.token.set(this._oauthService.getAccessToken());

    this._oauthService.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event.type === 'token_received') {
        this.token.set(this._oauthService.getAccessToken());

        this.isConnected() &&
          this.stompClient()?.disconnect(() => this.stompClient.set(undefined));

        this.token() &&
          environment.update_management.websocket_broker &&
          this.connect(this.token());
      }
    });

    this.token() && environment.update_management.websocket_broker
      ? this.connect(this.token())
      : this._logger.log(
          'WebSocketAPIService',
          'Cannot initialize: Missing authentication token',
        );
  }

  ngOnDestroy(): void {
    this.stompClient()?.disconnect(() => this.stompClient.set(undefined));
    this.activeSubscriptions.clear();
    this.pendingMessages.clear();
  }

  public send(message: object, topic: string): void {
    if (!this.token() || !environment.update_management.websocket_broker) {
      this._logger.log(
        'WebSocketAPIService',
        'Cannot send: Missing authentication token',
      );
      return;
    }

    if (!this.isConnected()) {
      this.pendingMessages.set(topic, message);
      interval(5000)
        .pipe(
          untilDestroyed(this),
          takeWhile(() => !this.isConnected()),
        )
        .subscribe({
          complete: () =>
            this.isConnected() && this.trySendMessage(message, topic),
        });
      return;
    }

    this.trySendMessage(message, topic);
  }

  private trySendMessage(message: object, topic: string): void {
    try {
      this.stompClient()?.send(topic, {}, JSON.stringify(message));
      this.pendingMessages.delete(topic);
    } catch (error) {
      this.pendingMessages.set(topic, message);
    }
  }

  public subscribe(topic: string): Observable<any> {
    return new Observable((observer) => {
      if (!this.token() || !environment.update_management.websocket_broker)
        return;

      if (!this.activeSubscriptions.has(topic)) {
        this.activeSubscriptions.set(topic, new Set());
      }
      this.activeSubscriptions.get(topic)?.add(observer);

      const trySubscribe = () => {
        try {
          this.stompClient()?.subscribe(topic, (msg) => observer.next(msg));
        } catch (error) {
          /* empty */
        }
      };

      if (!this.isConnected()) {
        interval(5000)
          .pipe(
            untilDestroyed(this),
            takeWhile(() => !this.isConnected()),
          )
          .subscribe({
            next: () => this.isConnected() && trySubscribe(),
            complete: () => this.isConnected() && trySubscribe(),
          });
      } else {
        trySubscribe();
      }

      return () => {
        const observers = this.activeSubscriptions.get(topic);
        if (observers?.delete(observer) && observers.size === 0) {
          this.activeSubscriptions.delete(topic);
        }
      };
    });
  }

  private connect(token?: string): void {
    if (!token) return;

    const client = Stomp.over(
      new SockJS(
        `${environment.update_management.websocket_broker}?${environment.websocketToken}=${encodeURIComponent(token)}`,
      ),
    );
    client.debug = () => {};

    client.connect(
      { Authorization: `Bearer ${token}` },
      () => {
        this.stompClient.set(client);
        this.activeSubscriptions.forEach((observers, topic) => {
          observers.forEach((observer) => {
            try {
              this.stompClient()?.subscribe(topic, (msg) => observer.next(msg));
            } catch (error) {
              /* empty */
            }
          });
        });
        this.pendingMessages.forEach((message, topic) =>
          this.trySendMessage(message, topic),
        );
      },
      () =>
        setTimeout(() => {
          this.stompClient.set(undefined);
          // If the token appears valid but connection still failed, try a silent refresh
          this._oauthService.getRefreshToken()
            ? this._oauthService.refreshToken() // Use refresh token if available
            : this._oauthService.silentRefresh(); // Fall back to silent refresh for implicit flow
        }, 5000),
    );
  }

  public testConnectionError(): void {
    const originalEndpoint = environment.update_management.websocket_broker;
    this.isConnected() &&
      this.stompClient()?.disconnect(() => {
        this.stompClient.set(undefined);
      });
    (environment.update_management as any).websocket_broker =
      'https://invalid-endpoint-test:1234';
    this.connect(this.token());

    setTimeout(() => {
      (environment.update_management as any).websocket_broker =
        originalEndpoint;
      this.connect(this.token());
    }, 3000);
  }
}
