<div
  class="row selection-wrapper justify-content-center align-items-center flex"
>
  <button
    devId="customerSelectButton"
    class="col customer-button"
    mat-button
    *ngIf="!objectSelected"
    (click)="customerSelect()"
  >
    <mat-icon>account_circle</mat-icon>
    {{ "HEADER.SELECT_CUSTOMER" | translate }}
  </button>
  <button
    devId="selectedCustomerButton"
    class="col customer-button"
    *ngIf="objectSelected"
    mat-button
    (click)="customerSelect()"
  >
    <span class="flex items-center justify-end">
      <mat-icon>account_circle</mat-icon>
      <span>{{ selectedCustomer["displayName"] }}</span>
    </span>
  </button>
  <button
    devId="removeCustomerSelectionButton"
    class="remove-button col-auto"
    mat-icon-button
    *ngIf="objectSelected"
    (click)="onRemoveSelection()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
