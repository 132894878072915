<div class="row justify-content-end align-items-center login-wrapper">
  <div class="col-6 col-md-auto login-content p-0">
    <div *ngIf="config.useAuth && !loggedIn" class="login-button">
      <button
        color="primary"
        class="mr-2"
        devId="loginButton"
        id="loginButton"
        mat-raised-button
        (click)="login()"
      >
        {{ "LOGIN" | translate }}
      </button>
    </div>
    <div
      *ngIf="loggedIn || !config.useAuth"
      class="login-menu-wrapper d-none d-md-flex justify-content-center align-items-center"
    >
      <!-- UserAccount -->
      <ng-container *ngIf="config.useAuth">
        <button
          mat-button
          [matMenuTriggerFor]="rootMenu"
          class="d-none d-md-inline-block"
        >
          <span class="flex items-center justify-end">
            <mat-icon>person</mat-icon>
            {{ name }}
            <mat-icon>arrow_drop_down</mat-icon>
          </span>
        </button>
        <mat-menu
          #rootMenu="matMenu"
          yPosition="below"
          [overlapTrigger]="false"
        >
          <a mat-menu-item [href]="accountUrl">
            <mat-icon>person</mat-icon>
            {{ "ACCOUNT" | translate }}
          </a>
          <button
            *ngIf="showRoleViewSelection()"
            mat-menu-item
            [mat-menu-trigger-for]="roleViewMenu"
          >
            {{ "ROLES_VIEW.NAME" | translate }}
          </button>
        </mat-menu>
        <mat-menu #roleViewMenu>
          <div mat-menu-item (click)="clearRoleView()">
            {{ "ROLES_VIEW.CLEAR" | translate }}
          </div>
          <div mat-menu-item (click)="setRoleView('Customer')">
            {{ "ROLES_VIEW.CUSTOMER" | translate }}
          </div>
          <div mat-menu-item (click)="setRoleView('Distributor')">
            {{ "ROLES_VIEW.DISTRIBUTOR" | translate }}
          </div>
        </mat-menu>
      </ng-container>
      <!-- Notification -->
      <ng-container *ngIf="config.useAuth && config.enableNotifications">
        <button
          mat-icon-button
          [matMenuTriggerFor]="notificationsMenu"
          class="d-none d-md-inline-block"
        >
          <mat-icon
            [matBadge]="notifications.length"
            aria-hidden="false"
            matBadgePosition="after"
            matBadgeColor="warn"
            matBadgeSize="small"
            [matBadgeHidden]="!notifications || notifications.length === 0"
            style="transform: scale(0.95)"
            >notifications</mat-icon
          >
        </button>
        <mat-menu #notificationsMenu="matMenu">
          <div
            mat-menu-item
            class="menu-item"
            *ngFor="let notification of notifications; let index = index"
            (click)="notificationClick(notification, index)"
          >
            <div class="row notification">
              <ng-container
                *ngIf="
                  getNotificationTitle(notification);
                  then titledNotification;
                  else untitledNotification
                "
              ></ng-container>

              <ng-template #titledNotification>
                <div class="col-12 notification-title">
                  <mat-icon>{{ notification.icon }}</mat-icon>
                  <b class="notification-heading">{{
                    getNotificationTitle(notification)
                  }}</b>
                  <button
                    class="pin-button"
                    mat-icon-button
                    (click)="
                      notificationPinned(notification); $event.stopPropagation()
                    "
                  >
                    <ng-container
                      *ngIf="notification.pinned; then unpin; else pin"
                    >
                    </ng-container>
                    <ng-template #pin>
                      <svg-icon
                        class="pin-icon"
                        src="assets/icons/pin.svg"
                      ></svg-icon>
                    </ng-template>
                    <ng-template #unpin>
                      <svg-icon
                        class="pin-icon"
                        src="assets/icons/unpin.svg"
                      ></svg-icon>
                    </ng-template>
                  </button>
                </div>
                <div class="notification-content col">
                  <span>
                    {{ getNotificationText(notification) }}
                  </span>
                </div>
              </ng-template>
              <ng-template #untitledNotification>
                <div class="notification-content col">
                  <mat-icon>{{ notification.icon }}</mat-icon>
                  <span>
                    {{ getNotificationText(notification) }}
                  </span>
                  <button
                    class="pin-button"
                    mat-icon-button
                    (click)="
                      notificationPinned(notification); $event.stopPropagation()
                    "
                  >
                    <ng-container
                      *ngIf="notification.pinned; then unpin; else pin"
                    >
                    </ng-container>
                    <ng-template #pin>
                      <svg-icon
                        class="pin-icon"
                        src="assets/icons/pin.svg"
                      ></svg-icon>
                    </ng-template>
                    <ng-template #unpin>
                      <svg-icon
                        class="pin-icon"
                        src="assets/icons/unpin.svg"
                      ></svg-icon>
                    </ng-template>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </mat-menu>
      </ng-container>
      <!-- Language -->
      <button
        devId="languageMenuButton"
        mat-button
        [matMenuTriggerFor]="subMenu"
        class="d-none d-md-inline-block"
      >
        <span class="flex items-center justify-end">
          <mat-icon>language</mat-icon>
          {{ activeLanguage.name }}
          <mat-icon>arrow_drop_down</mat-icon>
        </span>
      </button>
      <mat-menu devId="languageMenu" #subMenu="matMenu">
        <button
          devId="languageOption"
          *ngFor="let language of languages"
          mat-menu-item
          (click)="activeLanguage = language"
        >
          {{ language.name }}
        </button>
        <!-- <button mat-menu-item (click)="setLanguage('en-UK')">Englisch</button> -->
      </mat-menu>

      <!-- Help -->
      <button
        *ngIf="config.showHelp"
        devId="helpMenuButton"
        mat-icon-button
        class="d-none d-md-inline-block"
        (click)="requestManual()"
        [matTooltip]="'MANUAL_DOWNLOAD' | translate"
      >
        <mat-icon style="transform: scale(0.95)">help</mat-icon>
      </button>

      <!-- <button
        devId="darkModeMenuButton"
        mat-button
        [matMenuTriggerFor]="darkMode"
        class="d-none d-md-inline-block"
      >
        <span class="flex items-center justify-end">
          @if ((theme$ | async) === "dark") {
            <mat-icon style="transform: scale(0.95)">dark_mode</mat-icon>
            {{ "THEME.DARK" | translate }}
          }
          @if ((theme$ | async) === "light") {
            <mat-icon style="transform: scale(0.95)">light</mat-icon>
            {{ "THEME.LIGHT" | translate }}
          }
          @if ((theme$ | async) === "system") {
            <mat-icon style="transform: scale(0.95)">settings</mat-icon>
            {{ "THEME.SYSTEM" | translate }}
          }
          <mat-icon>arrow_drop_down</mat-icon>
        </span>
      </button>
      <mat-menu devId="darkModeMenu" #darkMode="matMenu">
        <button
          devId="darkModeOption"
          *ngFor="let mode of darkModes"
          mat-menu-item
          (click)="setTheme(mode)"
        >
          {{ "THEME." + mode.toUpperCase() | translate }}
        </button>
      </mat-menu> -->

      <!-- Logout -->
      <button
        *ngIf="config.useAuth"
        mat-button
        devId="logoutButton"
        (click)="logout()"
        class="d-none d-md-inline-block"
      >
        <span class="flex items-center justify-end">
          <mat-icon>exit_to_app</mat-icon>
          {{ "LOGOUT" | translate }}
        </span>
      </button>
    </div>
  </div>
</div>
