import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MENU_STRUKTUR } from '../../mockdata/mock.data';
import { AuthGuardService } from '../authGuard/authGuard.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(private auth: AuthGuardService) {}

  getMenu(roles: string[]): Observable<any> {
    return of(
      MENU_STRUKTUR.filter((category) => {
        return this.auth.containsOneRole(roles, category.neededRoles || []);
      }),
    );
  }
}
