import { ReceivedHeaderEvent } from '@/app/external-modules/header/actions/header';
import { EventType } from '@/app/external-modules/header/models/header-event';
import {
  getActiveLanguageSelect,
  getHeaderEventSelect,
  getLoggedInSelect,
  getSelectedCustomerSelect,
} from '@/app/external-modules/header/reducers/module';
import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { UtilsService } from '@/app/external-modules/utils/utils.service';
import { ProfileService } from '@/app/services/profile/profile.service';
import { CustomerFullDto } from '@/openapi';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin, of } from 'rxjs';
import { concatMap, filter } from 'rxjs/operators';
import { IStatistics } from '../../interfaces/statistics.interface';
import { IUpdate } from '../../interfaces/update.interface';
import { DeviceUpdateService } from '../../modules/device-update/services/device-update.service';
import { BewatecDashboardComponent } from '../../modules/library/components/bewatec-dashboard/bewatec-dashboard.component';
import {
  CardType,
  IChartData,
  IDashboardCard,
  IInfoData,
  ITabTableCard,
  ITableCard,
  ITableData,
} from '../../modules/library/components/bewatec-dashboard/interfaces/card-layout.interface';
import {
  IIconLayout,
  IObjectLayout,
  IStringLayout,
  LayoutType,
} from '../../modules/library/components/table/interfaces/layout.interface';
import { CustomerManagementService } from '../../services/customers/customer-management.service';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { Utils } from '../../utils';
import { IBridge } from './../../interfaces/bridge.interface';
import { IPage } from './../../interfaces/page.interface';
import {
  IBarChartCard,
  ITabTableData,
} from './../../modules/library/components/bewatec-dashboard/interfaces/card-layout.interface';
import { StatisticsService } from './statistics.service';

@Component({
  selector: 'bewa-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
@Subscriptions()
export class HomeComponent implements AfterViewInit {
  @ViewChild(BewatecDashboardComponent) dashBoard: any;
  userLoggedIn = false;
  latestUpdates: any[] = [];
  latestProfileUpdates: any[] = [];
  customerBridges: any[] = [];

  defaultColumns: string[] = [
    'currentState',
    'devices',
    'imageName',
    'downloadStarted',
    'installFinished',
  ];
  defaultProfileColumns: string[] = [
    'currentState',
    'devices',
    'configName',
    'downloadStarted',
  ];

  dashboardCards: IDashboardCard[] = [
    {
      cardId: 'info_msg',
      cardType: CardType.INFO,
      cardTitle: 'DASHBOARD.CARD.WELCOME.TITLE',
      cols: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 12;
        }
        return 12;
      },
      rows: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 3;
        }
        return 3;
      },
      data: <IInfoData>{
        infoTitle: 'DASHBOARD.CARD.WELCOME.INFO_TITLE',
        infoText: 'DASHBOARD.CARD.WELCOME.INFO_TEXT',
      },
      order: 1,
    },
    <ITableCard>{
      cardId: 'bridge_info',
      cardType: CardType.TABLE,
      cardTitle: 'DASHBOARD.CARD.CONNECT_SERVER.INFO_TITLE',
      cols: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 12;
        }
        return 8;
      },
      rows: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 8;
        }
        return 8;
      },
      data: <ITableData>{
        infoText: '',
        availableColumns: [
          {
            displayName: 'BEWATEC_TABLE.COLUMNS.CONNECT_SERVER.BRIDGE_NAME',
            internalName: 'bridgeName',
            layout: {
              type: LayoutType.STRING,
              valuePath: 'name',
              translate: true,
            },
          },
          {
            displayName: 'BEWATEC_TABLE.COLUMNS.CONNECT_SERVER.BRIDGE_STATE',
            internalName: 'onlineStatus',
            layout: <IObjectLayout>{
              type: LayoutType.OBJECT,
              valuePath: ['connected'],
              layout: [
                <IIconLayout>{
                  type: LayoutType.ICON,
                  valuePath: 'connected',
                  formatter: (value) => {
                    if (!value) {
                      return {
                        icon: 'warning',
                        color: 'warn',
                        tooltip: 'TOOLTIP.STATE.ERROR',
                      };
                    } else {
                      return;
                    }
                  },
                },
                <IStringLayout>{
                  type: LayoutType.STRING,
                  valuePath: 'connected',
                  formatter(value: any): any {
                    if (value) {
                      return 'Online';
                    } else {
                      return 'Offline';
                    }
                  },
                },
              ],
            },
          },
          {
            displayName:
              'BEWATEC_TABLE.COLUMNS.CONNECT_SERVER.BRIDGE_LAST_CHANGE',
            internalName: 'connectTimestamp',
            layout: {
              type: LayoutType.DATE,
              valuePath: 'connectTimestamp',
              translate: true,
            },
          },
          {
            roles: ['Super_Admin', 'Bewatec_Admin'],
            displayName: 'BEWATEC_TABLE.COLUMNS.CONNECT_SERVER.BRIDE_VERSION',
            internalName: 'oldBridge',
            layout: {
              type: LayoutType.STRING,
              valuePath: 'bridgeVersion',
              translate: true,
              formatter(value: any): any {
                if (value === 'NEW') {
                  return '>= 1.5.0';
                } else if (value === 'OLD') {
                  return '< 1.5.0';
                } else {
                  return 'Unknown';
                }
              },
            },
          },
        ],
        tableData: this.customerBridges,
        tableConfig: {
          componentId: 'home-component',
          defaultColumns: [
            'bridgeName',
            'onlineStatus',
            'connectTimestamp',
            'oldBridge',
          ],
          rowCompare: (row1: IUpdate, row2: IUpdate) => row1.id === row2.id,
          selectable: false,
          persistColumns: false,
          columnSortDisabled: true,
        },
      },
      order: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 6;
        }
        return 5;
      },
    },
    {
      cardId: 'used_devices',
      cardType: CardType.PIE_CHART,
      cardTitle: 'DASHBOARD.CARD.DEVICES.TITLE',
      cols: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 12;
        }
        return 4;
      },
      rows: 6,
      data: <IChartData>(<unknown>{
        chartName: 'pieChart',
        chartData: [],
        chartLabels: [],
        chartBackgroundColor: ['#002255', '#ACC947'],
        calcMissingColors: true,
      }),
      order: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 3;
        }
        return 3;
      },
    },
    <ITabTableCard>{
      cardId: 'last_updates',
      cardType: CardType.TAB_TABLE,
      cardTitle: '',
      cols: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 12;
        }
        return 8;
      },
      rows: 12,
      data: [
        <ITabTableData>{
          availableColumns: [
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.DEVICE_UPDATE.STATE',
              internalName: 'currentState',
              layout: <IObjectLayout>{
                type: LayoutType.OBJECT,
                valuePath: [
                  'downloadComplete',
                  'installationComplete',
                  'cancelled',
                  'finished',
                  'state',
                ],
                layout: [
                  <IIconLayout>{
                    type: LayoutType.ICON,
                    valuePath: [
                      'downloadComplete',
                      'installationComplete',
                      'cancelled',
                      'finished',
                      'state',
                    ],
                    formatter: (value) => {
                      if (value.state) {
                        switch (value.state) {
                          case 'IDLE':
                            return {
                              icon: 'hourglass_empty',
                              color: 'primary',
                              tooltip: 'TOOLTIP.STATE.IDLE',
                            };
                          case 'CANCELLED':
                            return {
                              icon: 'cancel',
                              color: 'warn',
                              tooltip: 'TOOLTIP.STATE.CANCELLED',
                            };
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                            return {
                              icon: 'warning',
                              color: 'warn',
                              tooltip: 'TOOLTIP.STATE.ERROR',
                            };
                        }
                      }
                      return {
                        icon: 'assets/icons/download-icon.svg',
                        type: 'svg',
                      };
                    },
                  },
                  <IIconLayout>(<unknown>{
                    type: LayoutType.ICON,
                    valuePath: [
                      'downloadComplete',
                      'installationComplete',
                      'cancelled',
                      'finished',
                      'state',
                    ],
                    formatter: (value: {
                      state: any;
                      downloadComplete: any;
                      finished: any;
                    }) => {
                      if (value.state) {
                        switch (value.state) {
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                          case 'CANCELLED':
                          case 'IDLE':
                            return;
                        }
                      }
                      if (value.downloadComplete || value.finished) {
                        return {
                          icon: 'done',
                          tooltip: 'TOOLTIP.STATE.DOWNLOAD_FINISHED',
                        };
                      } else {
                        return {
                          color: 'primary',
                          type: 'spinner',
                          tooltip: 'TOOLTIP.STATE.DOWNLOADING',
                        };
                      }
                    },
                  }),
                  <IIconLayout>{
                    type: LayoutType.ICON,
                    valuePath: [
                      'downloadComplete',
                      'installationComplete',
                      'cancelled',
                      'finished',
                      'state',
                    ],
                    formatter: (value) => {
                      if (value.state) {
                        switch (value.state) {
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                          case 'CANCELLED':
                          case 'IDLE':
                            return;
                        }
                      }
                      return {
                        icon: 'assets/icons/install-icon.svg',
                        type: 'svg',
                      };
                    },
                  },
                  <IIconLayout>(<unknown>{
                    type: LayoutType.ICON,
                    valuePath: [
                      'downloadComplete',
                      'installationComplete',
                      'cancelled',
                      'finished',
                      'state',
                    ],
                    formatter: (value: {
                      state: any;
                      downloadComplete: any;
                      installationComplete: any;
                      finished: any;
                    }) => {
                      if (value.state) {
                        switch (value.state) {
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                          case 'CANCELLED':
                          case 'IDLE':
                            return;
                        }
                      }

                      if (!value.downloadComplete) {
                        return {
                          icon: 'hourglass_empty',
                          color: 'primary',
                          tooltip: 'TOOLTIP.STATE.IDLE',
                        };
                      } else if (value.installationComplete && value.finished) {
                        return {
                          icon: 'done',
                          tooltip: 'TOOLTIP.STATE.INSTALL_FINISHED',
                        };
                      } else {
                        return {
                          color: 'primary',
                          type: 'spinner',
                          tooltip: 'TOOLTIP.STATE.INSTALLING',
                        };
                      }
                    },
                  }),
                ],
              },
            },
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.DEVICE_UPDATE.TARGET_TYPE',
              internalName: 'targetType',
              layout: {
                type: LayoutType.STRING,
                valuePath: 'groupTypeName',
                translate: true,
              },
            },
            {
              displayName:
                'BEWATEC_TABLE.COLUMNS.DEVICE_UPDATE.AFFECTED_DEVICES',
              internalName: 'devices',
              layout: { type: LayoutType.STRING, valuePath: 'deviceCount' },
            },
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.DEVICE_UPDATE.IMAGE_VERSION',
              internalName: 'imageName',
              layout: { type: LayoutType.STRING, valuePath: 'release.version' },
            },
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.DEVICE_UPDATE.DOWNLOAD_START',
              internalName: 'downloadStarted',
              layout: { type: LayoutType.DATE, valuePath: 'beginDownloadTime' },
            },
            {
              displayName:
                'BEWATEC_TABLE.COLUMNS.DEVICE_UPDATE.INSTALL_FINISHED',
              internalName: 'installFinished',
              layout: {
                type: LayoutType.DATE,
                valuePath: 'endInstallationTime',
              },
            },
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.DEVICE_UPDATE.CUSTOMER',
              internalName: 'customerName',
              roles: ['Bewatec_Admin', 'Distributor'],
              layout: { type: LayoutType.STRING, valuePath: 'customerName' },
            },
          ],
          tableConfig: {
            componentId: 'home-component',
            defaultColumns: this.defaultColumns,
            rowCompare: (row1: IUpdate, row2: IUpdate) => row1.id === row2.id,
            selectable: false,
            persistColumns: false,
            columnSortDisabled: true,
          },
          tableData: this.latestUpdates,
          tabTitle: 'DASHBOARD.CARD.SW_UPDATES.TITLE',
          internalName: 'device-update',
        },
        <ITabTableData>{
          availableColumns: [
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.PROFILE_UPDATE.STATE',
              internalName: 'currentState',
              layout: <IObjectLayout>{
                type: LayoutType.OBJECT,
                valuePath: ['state'],
                layout: [
                  <IIconLayout>{
                    type: LayoutType.ICON,
                    valuePath: 'state',
                    formatter: (value) => {
                      if (value) {
                        switch (value) {
                          case 'IDLE':
                            return {
                              icon: 'hourglass_empty',
                              color: 'primary',
                              tooltip: 'TOOLTIP.STATE.IDLE',
                            };
                          case 'CANCELLED':
                            return {
                              icon: 'cancel',
                              color: 'warn',
                              tooltip: 'TOOLTIP.STATE.CANCELLED',
                            };
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                            return {
                              icon: 'warning',
                              color: 'warn',
                              tooltip: 'TOOLTIP.STATE.ERROR',
                            };
                        }
                      }
                      return {
                        icon: 'assets/icons/download-icon.svg',
                        type: 'svg',
                      };
                    },
                  },
                  <IIconLayout>(<unknown>{
                    type: LayoutType.ICON,
                    valuePath: 'state',
                    formatter: (state: string) => {
                      if (state) {
                        switch (state) {
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                          case 'CANCELLED':
                          case 'IDLE':
                            return;
                        }
                      }
                      if (
                        state === 'APPLY_FINISHED' ||
                        state === 'DOWNLOAD_FINISHED'
                      ) {
                        return {
                          icon: 'done',
                          tooltip: 'TOOLTIP.STATE.DOWNLOAD_FINISHED',
                        };
                      } else {
                        return {
                          color: 'primary',
                          type: 'spinner',
                          tooltip: 'TOOLTIP.STATE.DOWNLOADING',
                        };
                      }
                    },
                  }),
                  <IIconLayout>{
                    type: LayoutType.ICON,
                    valuePath: 'state',
                    formatter: (state) => {
                      if (state) {
                        switch (state) {
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                          case 'CANCELLED':
                          case 'IDLE':
                            return;
                        }
                      }
                      return {
                        icon: 'assets/icons/install-icon.svg',
                        type: 'svg',
                      };
                    },
                  },
                  <IIconLayout>(<unknown>{
                    type: LayoutType.ICON,
                    valuePath: 'state',
                    formatter: (state: string) => {
                      if (state) {
                        switch (state) {
                          case 'ERROR':
                          case 'APPLY_FINISHED_WITH_ERRORS':
                          case 'DOWNLOADING_AND_INSTALLING_ERROR':
                          case 'CANCELLED':
                          case 'IDLE':
                            return;
                        }
                      }

                      if (state === 'DOWNLOAD_FINISHED') {
                        return {
                          icon: 'hourglass_empty',
                          color: 'primary',
                          tooltip: 'TOOLTIP.STATE.IDLE',
                        };
                      } else if (state === 'APPLY_FINISHED') {
                        return {
                          icon: 'done',
                          tooltip: 'TOOLTIP.STATE.INSTALL_FINISHED',
                        };
                      } else {
                        return {
                          color: 'primary',
                          type: 'spinner',
                          tooltip: 'TOOLTIP.STATE.INSTALLING',
                        };
                      }
                    },
                  }),
                ],
              },
            },
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.PROFILE_UPDATE.TARGET_TYPE',
              internalName: 'targetType',
              layout: {
                type: LayoutType.STRING,
                valuePath: 'groupTypeName',
                translate: true,
              },
            },
            {
              displayName:
                'BEWATEC_TABLE.COLUMNS.PROFILE_UPDATE.AFFECTED_DEVICES',
              internalName: 'devices',
              layout: {
                type: LayoutType.STRING,
                valuePath: 'number_of_devices',
              },
            },
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.PROFILE_UPDATE.CONFIG_NAME',
              internalName: 'configName',
              layout: {
                type: LayoutType.STRING,
                valuePath: 'deviceProfileName',
              },
            },
            {
              displayName:
                'BEWATEC_TABLE.COLUMNS.PROFILE_UPDATE.DOWNLOAD_START',
              internalName: 'downloadStarted',
              layout: {
                type: LayoutType.DATE,
                valuePath: 'beginn_download_time',
              },
            },
            {
              displayName: 'BEWATEC_TABLE.COLUMNS.PROFILE_UPDATE.CUSTOMER',
              internalName: 'customerName',
              roles: ['Bewatec_Admin', 'Distributor'],
              layout: { type: LayoutType.STRING, valuePath: 'customerName' },
            },
          ],
          tableConfig: {
            componentId: 'home-component',
            defaultColumns: this.defaultProfileColumns,
            rowCompare: (row1: IUpdate, row2: IUpdate) => row1.id === row2.id,
            selectable: false,
            persistColumns: false,
            columnSortDisabled: true,
          },
          tableData: this.latestUpdates,
          tabTitle: 'DASHBOARD.CARD.PROFILE_UPDATES.TITLE',
          internalName: 'profile-update',
        },
      ],
      order: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 2;
        }
        return 2;
      },
    },
    {
      cardId: 'online_offline_devices',
      cardType: CardType.PIE_CHART,
      cardTitle: 'DASHBOARD.CARD.ONLINE_OFFLINE.TITLE',
      cols: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 12;
        }
        return 4;
      },
      rows: 6,
      data: <IChartData>(<unknown>{
        chartName: 'online_offline',
        chartData: [],
        chartLabels: ['Online', 'Offline'],
        chartBackgroundColor: ['#ACC947', '#EF871D'],
        calcMissingColors: false,
      }),
      order: 4,
    },
    <IBarChartCard>{
      cardId: 'software_versions',
      cardType: CardType.BAR_CHART,
      cardTitle: 'DASHBOARD.CARD.SW_VERSIONS.TITLE',
      maxNameLength: 9,
      logarithmic: true,
      cols: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 12;
        }
        return 4;
      },
      rows: 8,
      data: <IChartData>(<unknown>{
        chartName: 'versions',
        chartData: [],
        chartLabels: [],
        chartBackgroundColor: ['#EF871D', '#ACC947'],
        calcMissingColors: true,
      }),
      order: () => {
        if (this.headerUtils.mediaBreakpointDown('md', window.innerWidth)) {
          return 5;
        }
        return 6;
      },
    },
  ];

  @Unsubscribe()
  private readonly _selectedCustomerSubscription$: Subscription;
  @Unsubscribe()
  private readonly _deselectCustomerSubscription$: Subscription;
  @Unsubscribe()
  private _statisticsSubscription: Subscription;
  @Unsubscribe()
  private _updatesSubscription: Subscription;
  @Unsubscribe()
  private loggedInSubscription: Subscription;
  @Unsubscribe()
  private _customerSubscription: Subscription;
  @Unsubscribe()
  private _activeLanguageSubscription: Subscription;
  @Unsubscribe()
  deviceUpdateSubscription: Subscription;
  @Unsubscribe()
  profileUpdateSubscription: Subscription;
  @Unsubscribe()
  translationSubscription: Subscription;
  //@Unsubscribe()
  bridgeSubscription: Subscription;
  private customers: any[] = [];
  private _selectedCustomer: CustomerFullDto;
  private _customerSelected = false;
  private _initialized = false;

  constructor(
    private store: Store<any>,
    private updateService: DeviceUpdateService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private statisticsService: StatisticsService,
    private customerService: CustomerManagementService,
    private utils: Utils,
    private headerUtils: UtilsService,
    private profileService: ProfileService,
    private websocket: WebsocketService,
  ) {
    if (!this.utils.isCustomer()) {
      this.defaultColumns.push('customerName');
      this.defaultProfileColumns.push('customerName');
    }

    this.loggedInSubscription = this.store
      .select(getLoggedInSelect)
      .subscribe((loggedIn) => {
        if (loggedIn) {
          this.userLoggedIn = true;
        } else {
          this.userLoggedIn = false;
        }
      });

    this._selectedCustomerSubscription$ = this.store
      .select(getSelectedCustomerSelect)
      .subscribe((newSelection) => {
        if (
          this.router.isActive(
            (this.route.snapshot as any)['_routerState'].url,
            false,
          ) &&
          newSelection
        ) {
          this._selectedCustomer = newSelection as CustomerFullDto;
          this._customerSelected = true;
          if (this._initialized) {
            this.initDashboardValues();
          }
        }
      });

    // Subscribe for headerEvents
    this._deselectCustomerSubscription$ = this.store
      .select(getHeaderEventSelect)
      .subscribe((headerEvent) => {
        if (headerEvent) {
          if (
            headerEvent.eventType === EventType.DeselectCustomer &&
            this.router.isActive(
              (this.route.snapshot as any)['_routerState'].url,
              false,
            )
          ) {
            this._customerSelected = false;
            if (this._initialized) {
              this.initDashboardValues();
            }
            this.store.dispatch(new ReceivedHeaderEvent());
          }
        }
      });

    this.translateService.setDefaultLang('de-DE');
    this._activeLanguageSubscription = this.store
      .select(getActiveLanguageSelect)
      .pipe(
        filter((activeLanguage) => !!activeLanguage),
        concatMap((activeLanguage) => {
          if (activeLanguage) {
            return this.translateService.use(activeLanguage.code);
          }
          return of(undefined);
        }),
      )
      .subscribe((result) => {
        this.updateInfoText();
        this.updateBridgeState();
      });
  }

  initDashboardValues() {
    this.loadUpdates();
    this.loadProfileUpdates();
    this.updateInfoText();
    this.updateBridgeState();
    this.loadStatistics();
  }

  loadUpdates() {
    this.deviceUpdateSubscription = this.updateService
      .getDeviceUpdates('0', '9')
      .pipe(
        concatMap((result: IPage<any>) => {
          this.latestUpdates = result.content;

          if (!this.utils.isCustomer()) {
            if (!this._customerSelected) {
              const updateCustomers: any[] = [];
              const customerIds: any[] = [];
              this.latestUpdates.forEach((update) => {
                if (!customerIds.some((el) => el === update.customerId)) {
                  updateCustomers.push(
                    this.customerService.getCustomerById(update.customerId),
                  );
                  customerIds.push(update.customerId);
                }
              });

              return forkJoin(updateCustomers);
            }
          }
          return of(undefined);
        }),
      )
      .subscribe((customers) => {
        if (!this.utils.isCustomer()) {
          if (!this._customerSelected) {
            this.latestUpdates.forEach((update) => {
              update.customerName = customers?.find(
                (customer) => customer.id.toString() === update.customerId,
              ).displayName;
            });
          } else {
            this.latestUpdates.forEach((update) => {
              update.customerName = this._selectedCustomer['displayName'];
            });
          }
        }

        this.dashBoard.update('last_updates', 'device-update', <ITableData>{
          tableData: this.latestUpdates,
        });
      });
  }

  loadProfileUpdates() {
    this.profileUpdateSubscription = this.profileService
      .getUpdateSessions('0', '9')
      .pipe(
        concatMap((result: IPage<any>) => {
          this.latestProfileUpdates = result.content;

          if (!this.utils.isCustomer()) {
            if (!this._customerSelected) {
              const updateCustomers: any[] = [];
              const customerIds: any[] = [];
              this.latestProfileUpdates.forEach((update) => {
                if (!customerIds.some((el) => el === update.customerId)) {
                  updateCustomers.push(
                    this.customerService.getCustomerById(update.customerId),
                  );
                  customerIds.push(update.customerId);
                }
              });

              return forkJoin(updateCustomers);
            }
          }
          return of(undefined);
        }),
      )
      .subscribe((customers) => {
        if (!this.utils.isCustomer()) {
          if (!this._customerSelected) {
            this.latestProfileUpdates.forEach((update) => {
              update.customerName = customers?.find(
                (customer) => customer.id.toString() === update.customerId,
              ).displayName;
            });
          } else {
            this.latestProfileUpdates.forEach((update) => {
              update.customerName = this._selectedCustomer['displayName'];
            });
          }
        }

        this.dashBoard.update('last_updates', 'profile-update', <ITableData>{
          tableData: this.latestProfileUpdates,
        });
      });
  }

  loadStatistics() {
    this._statisticsSubscription = this.statisticsService
      .getStatistics()
      .subscribe((statistics: IStatistics) => {
        const versions: any[] = [];
        const versionCount: number[] = [];
        statistics.softwareVersions.forEach((entry) => {
          if (entry.name && entry.name !== 'null') {
            versions.push(entry.name);
            versionCount.push(entry.value);
          }
        });

        const deviceTypes: string[] = [];
        const typeCount: number[] = [];
        statistics.deviceTypes.map((entry) => {
          deviceTypes.push(entry.name);
          typeCount.push(entry.value);
        });

        const stateCount: number[] = [0, 0];
        statistics.onlineOfflineDevices.map((entry) => {
          if (entry.name.toLowerCase() === 'online') {
            stateCount.splice(0, 1, entry.value);
          }
          if (entry.name.toLowerCase() === 'offline') {
            stateCount.splice(1, 1, entry.value);
          }
        });

        this.dashBoard.update('software_versions', <IChartData>{
          chartLabels: versions,
          chartData: versionCount,
        });
        this.dashBoard.update('used_devices', <IChartData>{
          chartLabels: deviceTypes,
          chartData: typeCount,
        });
        this.dashBoard.update('online_offline_devices', <IChartData>{
          chartData: stateCount,
        });
      });
  }

  updateInfoText() {
    let translateKey = 'DASHBOARD.CARD.WELCOME.INFO_TEXT_ADMIN';
    let interpolateParams = {};

    if (this.utils.isBewatecAdmin()) {
      if (this._customerSelected) {
        translateKey = 'DASHBOARD.CARD.WELCOME.INFO_TEXT_ADMIN_CUSTOMER';
        interpolateParams = { customer: this._selectedCustomer['displayName'] };
      } else {
        translateKey = 'DASHBOARD.CARD.WELCOME.INFO_TEXT_ADMIN';
      }
    } else if (this.utils.isDistributor()) {
      if (this._customerSelected) {
        translateKey = 'DASHBOARD.CARD.WELCOME.INFO_TEXT_DISTRIBUTOR_CUSTOMER';
        interpolateParams = { customer: this._selectedCustomer['displayName'] };
      } else {
        translateKey = 'DASHBOARD.CARD.WELCOME.INFO_TEXT_DISTRIBUTOR';
      }
    } else if (this.utils.isCustomer()) {
      translateKey = 'DASHBOARD.CARD.WELCOME.INFO_TEXT_CUSTOMER';
    }

    let infoTitle = '';
    this.translationSubscription = this.translateService
      .get('DASHBOARD.CARD.WELCOME.INFO_TITLE', {
        name: this.utils.getUserName(),
      })
      .pipe(
        concatMap((result: string) => {
          infoTitle = result;
          return this.translateService.get(translateKey, interpolateParams);
        }),
      )
      .subscribe((result) => {
        if (this.dashBoard) {
          this.dashBoard.update('info_msg', <IInfoData>(<unknown>{
            infoTitle: infoTitle,
            infoText: result,
            infoIcon: undefined,
          }));
        }
      });
  }

  updateBridgeState() {
    if (
      ((this.utils.isBewatecAdmin() ||
        this.utils.isSuperAdmin() ||
        this.utils.isDistributor()) &&
        this._customerSelected) ||
      this.utils.isCustomer()
    ) {
      const customerId = this._selectedCustomer
        ? this._selectedCustomer.id
        : this.utils.getCustomerId();
      // load bridge state
      this.customerService
        .getCustomerBridges(customerId)
        .subscribe((bridges: IBridge[]) => {
          this.updateBridgeCardData(bridges);
        });

      this.bridgeSubscription = this.websocket
        .subscribe(`/ws/bridges/customer/${customerId}`)
        .pipe(
          concatMap((msg) => {
            // add notification with new bridge state
            return this.customerService.getCustomerBridges(customerId);
          }),
        )
        .subscribe((bridges: IBridge[]) => {
          this.updateBridgeCardData(bridges);
        });
    } else {
      if (this.dashBoard) {
        this.dashBoard.update('bridge_info', <ITableData>(<unknown>{
          tableData: [],
          infoText: 'DASHBOARD.CARD.CONNECT_SERVER.SELECT_CUSTOMER',
        }));
      }
    }
  }

  private updateBridgeCardData(bridges: IBridge[]) {
    this.customerBridges = [];
    let info_text: any;
    // format data

    if (bridges) {
      bridges.forEach((bridge) => {
        if (bridge && bridge.connectTimestamp) {
          if (!bridge.connected) {
            info_text =
              'DASHBOARD.CARD.CONNECT_SERVER.BRIDGE_OFFLINE_EXPLANATION';
          }
          this.customerBridges.push(bridge);
        }
      });
    }

    // update card
    if (this.dashBoard) {
      if (!this.customerBridges || this.customerBridges.length == 0) {
        info_text = 'DASHBOARD.CARD.CONNECT_SERVER.INFO_TEXT_NO_DATA';
      }
      this.dashBoard.update('bridge_info', <ITableData>{
        tableData: this.customerBridges,
        infoText: info_text,
      });
    }
  }

  ngAfterViewInit() {
    this._initialized = true;
    this.initDashboardValues();
  }
}
